import React from 'react'

import { Intro, Skills, Contact, Projects } from 'Components/landing'
import { Layout, SEO } from 'Common'

const IndexPage = () => (
  <Layout>
    <SEO />
    <Intro />
    <Projects />
    <Skills />
    <Contact />
  </Layout>
)

export default IndexPage
